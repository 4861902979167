<template>
  <div :class="['relative flex items-center', $attrs.class]" @click="!disableClick && onClickText">
    <div
      :ref="getTextRef"
      :class="[
        'line-clamp-1 break-all whitespace-pre-wrap ',
        isEditMode ? 'invisible ' + inputClass : 'visible',
        internalTextClass,
      ]"
      :data-test="dataTest"
    >
      {{ internalText || placeholder }}
    </div>
    <UInput
      v-if="isEditMode"
      ref="inputRef"
      v-model="internalText"
      autofocus
      :placeholder="placeholder"
      :maxlength="maxLength"
      :class="[
        'w-full absolute inset-0 whitespace-pre-line',
        { '!cursor-not-allowed': disabled },
        inputClass,
      ]"
      :input-class="internalTextClass"
      :disabled="disabled"
      data-test="resize-input"
      @keydown.esc="onEsc"
      @blur="onChange"
      @focus="emit('focus', true)"
      @click.prevent.exact.stop
      @keydown.enter.prevent.stop="onEnter"
    />
  </div>
</template>

<script setup lang="ts">
import { throttle } from 'lodash-es'

const props = defineProps({
  dataTest: {
    type: String,
  },
  maxLength: {
    type: Number,
  },
  placeholder: {
    type: String,
  },
  textClass: {
    type: String,
  },
  inputClass: {
    type: String,
  },
  tooltip: {
    type: Object as PropType<Record<string, object | string | boolean>>,
  },
  editMode: {
    type: Boolean,
  },
  getTextRef: {
    type: Function as PropType<(ref: Element | ComponentPublicInstance | null) => void>,
  },
  preventFocusAfterEnter: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  disableClick: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<{
  (e: 'change', value: string, callback: (value: string) => void): void
  (e: 'focus', isFocus: boolean): void
  (e: 'enter'): void
}>()

const internalText = defineModel('text', {
  type: String,
  default: '',
})

const isEditMode = ref(props.editMode)
const inputRef = ref()
const preventFocus = ref(false)

const internalTextClass = computed(() => {
  const commonClass = 'px-1.5 py-0.5 min-h-4 shadow-none'

  return [props.textClass, commonClass].join(' ')
})

const onEsc = () => {
  internalText.value = ''
  isEditMode.value = false
  emit('focus', false)
}

const onClickText = () => {
  if (props.disabled) {
    return
  }

  if (preventFocus.value) {
    preventFocus.value = false
    return
  }

  isEditMode.value = true
  emit('focus', true)

  inputRef.value?.$el.querySelector('input')?.focus()
}

const onChange = () => {
  emit('change', internalText.value, (value) => {
    internalText.value = value
  })

  if (!props.editMode) {
    isEditMode.value = false
  }

  emit('focus', isEditMode.value)
}

const onEnter = throttle((event: KeyboardEvent) => {
  (event.target as HTMLTextAreaElement)?.blur()
  emit('enter')
  if (props.preventFocusAfterEnter) {
    preventFocus.value = true
  }
}, 300, { trailing: false })

defineExpose({
  focus: () => {
    onClickText()
  },
  blur: () => {
    inputRef.value?.$el.querySelector('input')?.blur()
  },
})
</script>
